<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：供应商选择组件
开始时间：2021-04-06
开发人员：万攀,康泽鑫
最后修改：2021-07-06
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="List">
    <el-dialog title="供应商分类选择" :visible.sync="show_supplierBox" top="8vh" :before-close="colse_createBox" width="65%"
      append-to-body>
      <!-- 列表总框 -->
      <div class="billing_dialog_table" id="supplierListBox">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" @click="getSupplierDataCondition(0)"
            :class="{'classfi_title_sel':curClassify==0}">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <!-- 分类列表 -->
            <div class="classfi_list">
              <ul>
                <li :class="{classfi_ItemSel:curClassify==elem.id}" v-for="(elem,index) in SupplierType"
                  v-show="elem.is_enable == 1" @click="getSupplierDataCondition(elem.id)">{{elem.name}}</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" placeholder="请输入查询内容" v-model="currSearchVal">
              <i class="el-icon-search" @click="getSupplierDataCondition(curClassify)"></i>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list" id="wrapper_protable">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="supplierData" height="100%" border style="width: 100%" @select="getselectedSupplier"
                @select-all="getSelectedCurrentSupplier">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 判断是单选还是多选 -->
                <el-table-column fixed width="45" v-if="isMultiple == 0">
                  <template slot-scope="scope">
                    <el-radio :label="scope.$index" v-model="selectedSupplierIndex"
                      @change.native="getSelectedSupplier(scope.$index,scope.row)">{{''}}</el-radio>
                  </template>
                </el-table-column>
                <el-table-column type="selection" fixed width="45" v-if="isMultiple == 1"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="supplierNo" label="编号" sortable width="150"></el-table-column>
                <el-table-column prop="fullName" label="全称" width="210"></el-table-column>
                <!-- <el-table-column prop="simpleName" label="简称" width="100"></el-table-column> -->
                <el-table-column prop="email" label="邮箱" width="160"></el-table-column>
                <el-table-column prop="linkmanName" label="联系人姓名" width="80"></el-table-column>
                <el-table-column prop="phone" label="电话" width="130"></el-table-column>
                <el-table-column prop="mobile" label="手机" width="130"></el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="currentPageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalPate">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_supplier">确认</el-button>
        <el-button size="mini" @click="colse_createBox">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  import api from '@/network/system/dictionary'; //数据字典相关
  import req from '@/network/datum/supplier'; //供应商相关接口

  export default {
    props: {
      //单选还是多选(0:单选 1:多选)
      isMultiple: {
        type: Number,
        default: 0
      },
    },
    name: 'list_supplier',
    data() {
      return {
        // 当前组件数据
        supplierData: [], //供应商数据数据当前组件数据
        curClassify: 0, //0表示全部,默认为全部

        //选中产品的数据
        selectedSupplierIndex: '', //已选供应商下标(radio绑定值)
        selectedSupplier: {}, //已选供应商
        currSearchVal: '', //当前输入框数据

        //分页相关数据
        currentPage: 1, //当前页
        currentPageSize: 20, //页面大小
        totalPate: 0, //总条数
      }
    },
    created() {
      //初始化vuex数据数据
      this.initVuexData();
    },
    mounted() {
      //获取供应商数据
      this.$nextTick(() => {
        this.getSupplierDataCondition(0);
      })
    },
    computed: {
      ...mapState({
        show_supplierBox: state => state.commComponent.show_supplierBox, //控制显示产品选择弹框组件
        SupplierType: state => state.system.SupplierType, //收款类型数据
      }),
    },
    watch: {},
    methods: {
      ...mapMutations([
        'SHOW_SUPPLIERBOX', //控制客户选择弹框是否显示
      ]),
      ...mapActions([
        'getSupplierClassfiy', //获取供应商分类
      ]),
      /* 请求供应商分类 */
      initVuexData() {
        //货品供应商分类
        if (this.SupplierType.length == 0) {
          this.getSupplierClassfiy();
        }
      },

      /* 根据分类id查找供应商 */
      getSupplierDataCondition(type) {
        //获取当前分类id
        this.curClassify = type;
        //定义默认数据
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          searchText: this.currSearchVal, //搜索框内容
        }

        // 判断其他查询条件
        if (type != 0) { //分类查询
          data.supplierType = type;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#supplierListBox", 4, '供应商列表信息获取中,请稍候...');
        //请求数据
        req.findSupplierByCondition(data).then(res => {
          this.loading.close();
          //判断接口返回数据
          if (res.code == 200) {
            //清空已选
            this.selectedSupplierIndex = "";
            //获取所有供应商信息
            this.supplierData = res.data.rows
            //获取总记录数
            this.totalPate = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取已选数据(selection:已选数组  row:当前勾选数据) */
      getselectedSupplier(selection, row) {
        this.selectedSupplier = selection; //当前选择的数据
      },

      /* 全选当前页数据*/
      getSelectedCurrentSupplier(selection) {
        this.selectedSupplier = selection; //当前选择的数据
      },

      /* 页面数据总量发生变化触发 */
      handleSizeChange(val) {
        //将数据存入列表
        this.currentPageSize = val;
        //获取产品列表数据
        this.getSupplierDataCondition(this.curClassify);
      },

      /* 分页页码发生变化触发 */
      handleCurrentChange(val) {
        //将数据存入列表
        this.currentPage = val;
        //获取产品列表数据
        this.getSupplierDataCondition(this.curClassify);
      },

      /* 获取选择的供应商信息 */
      getSelectedSupplier(index, selection) {
        this.selectedSupplierIndex = index; //当前选择的下标
        this.selectedSupplier = selection; //当前选择的数据
      },

      /* 关闭弹框 */
      colse_createBox() {
        this.SHOW_SUPPLIERBOX(false);
      },

      /* 提交所选供应商 */
      commit_supplier() {
        //将数据发送到父组件
        this.$emit("SelectedData", this.selectedSupplier);
        //关闭弹框
        this.SHOW_SUPPLIERBOX(false);
      },
    }
  }
</script>

<style>
</style>
