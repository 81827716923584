<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：工序新增编辑组件
	开始时间：2021-08-18
	开发人员：刘巍骏
	最后修改：2021-08-18
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-dialog :title="(operatedState==0 ? '新增' : '修改') +'工序' " top="10vh" :visible.sync="show_processform"
      :before-close="colseEditProcessBox" width="650px" append-to-body :close-on-click-modal="false">
      <div class="billing_dialog_form scollStyle form">
        <el-form :model="processData" :rules="form_rules" ref="add_ruleform" label-width="120px" size="small">
          <el-row>
            <el-col :span="24">
              <span class="formTitle">基本信息</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工序编码:" prop="encoding">
                <el-input v-model="processData.encoding" placeholder="请输入工序编码" :disabled="operatedType == 1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工序名称:" prop="name">
                <el-input v-model="processData.name" placeholder="请输入工序名称" :disabled="operatedType == 1"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="生产类型:">
                <el-select v-model="processData.type" class="iteminput" size="small" placeholder="请选择" :disabled="operatedType == 1">
                  <el-option v-for="item in ManufactureType" v-if="item.is_enable == 1 " :key="item.id"
                    :label="item.name" :value="item.name" @click.native="getTypeData(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="processData.typeData.code == 'DICT-SMM-002'">
              <!-- 工序主表供应商 -->
              <el-form-item label="供应商:" v-if="operatedType == 0">
                <el-tooltip placement="top" :disabled="processData.supplier == '' ">
                  <div slot="content">{{processData.supplier}}</div>
                  <el-input v-model="processData.supplier" placeholder="请选择供应商"
                    oninput="this.value=this.value.replace(/\S+/g,'')" @click.native="judgeRelatedData(3)" size="small"
                    suffix-icon="el-icon-more"></el-input>
                </el-tooltip>
              </el-form-item>
              <!-- 工序单供应商 -->
              <el-form-item label="供应商:" v-if="operatedType == 1">
                <el-select v-model="processData.supplier" placeholder="请选择">
                  <el-option v-for="item in supplierList" :key="item.supplierId" :label="item.supplierName"
                    :value="item.supplierId" @click.native="getSupplierListObj(item)">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="processData.typeData.code == 'DICT-SMM-001'">
              <el-form-item label="生产车间:" prop="department">
                <el-tooltip placement="top" :disabled="processData.department == '' ">
                  <div slot="content">{{processData.department}}</div>
                  <el-input v-model="processData.department" placeholder="请选择负责部门"
                    oninput="this.value=this.value.replace(/\S+/g,'')" @click.native="judgeRelatedData(1)" size="small"
                    suffix-icon="el-icon-more"></el-input>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工序负责人:" prop="personnel">
                <el-tooltip placement="top" :disabled="processData.personnel == '' ">
                  <div slot="content">{{processData.personnel}}</div>
                  <el-input v-model="processData.personnel" placeholder="请选择负责人"
                    oninput="this.value=this.value.replace(/\S+/g,'')" @click.native="judgeRelatedData(2)" size="small"
                    suffix-icon="el-icon-more"></el-input>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工序分类:" prop="classfiy">
                <el-select v-model="processData.classfiy" class="iteminput" size="small" placeholder="请选择" :disabled="operatedType == 1">
                  <el-option v-for="item in ProductionBomType" v-if="item.is_enable == 1 " :key="item.id"
                    :label="item.name" :value="item.name" @click.native="getBomTypeData(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="formTitle">人工成本</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="计费方式:">
                <el-select v-model="processData.cost" class="iteminput" size="small" placeholder="请选择" :disabled="!disableCost">
                  <el-option v-for="item in CostType" :key="item.id" v-if="item.is_enable == 1 " :label="item.name"
                    :value="item.name" @click.native="getCostData(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预计用时:">
                <el-input v-model="processData.time" size="small" placeholder="单位:分钟" @change="estimatedTotalPrice()"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="operatedType == 1"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="初始单价:">
                <el-input v-model="processData.price" placeholder="请输入工价" size="small" @change="estimatedTotalPrice()"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" :disabled="!disableCost">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="执行人数:">
                <el-input v-model="processData.work_num" @change="estimatedTotalPrice()" oninput="this.value=this.value.replace(/\D/g,'')"
                  placeholder="请输入执行人数"></el-input>
              </el-form-item>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="预计总工价:">
                <el-input v-model="processData.total_labour_cost" size="small" placeholder="请输入总工价"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="!disableCost"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item class="costClass"  label="制造分摊成本:">
                <el-input v-model="processData.manufacturing_allocation_cost" size="small" placeholder="请输入制造分摊成本"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="!disableCost"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-radio class="manufacturingTypeClass"  v-model="processData.manufacturingType" label="0">按小时分摊</el-radio>
              <el-radio class="manufacturingTypeClass"  v-model="processData.manufacturingType" label="1">平均分摊</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item class="costClass" label="管理分摊成本:">
                <el-input v-model="processData.manage_cost_allocation" size="small" placeholder="请输入管理分摊成本"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="!disableCost"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-radio class="manufacturingTypeClass"  v-model="processData.manageType" label="0">按小时分摊</el-radio>
              <el-radio class="manufacturingTypeClass"  v-model="processData.manageType" label="1">平均分摊</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item class="costClass" label="其它分摊成本:">
                <el-input v-model="processData.other_apportioned_costs" size="small" placeholder="请输入其它分摊成本"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="!disableCost"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-radio class="manufacturingTypeClass"  v-model="processData.otherType" label="0">按小时分摊</el-radio>
              <el-radio class="manufacturingTypeClass"  v-model="processData.otherType" label="1">平均分摊</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="formTitle">辅助项</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="最低开工费:">
                <el-input v-model="processData.min_work_amount" size="small" placeholder="请输入费用"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="operatedType == 1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最低开工费配额:">
                <el-input v-model="processData.min_work_number" size="small" placeholder="请输入配额"
                  oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="operatedType == 1"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
             <el-form-item label="税率:">
               <el-input v-model="processData.tax_rage" size="small" placeholder="请输入税率"
                 oninput="this.value=this.value.replace(/\s+/g,'')" :disabled="!disableCost"></el-input>
             </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="operatedType == 0">
            <el-col :span="12">
              <el-form-item label="启用:">
                <el-checkbox v-model="processData.is_enable"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="图纸:">
                <el-upload :action="APi" :limit="3" :headers="headers" :before-remove="removeFile"
                  :on-success="getImgUrl" :file-list="imgUrl" :on-preview="previewDrawing">
                  <el-button type="primary">上传图纸</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input v-model="processData.remark" type="textarea" :rows="2" placeholder="请输入内容"
                  oninput="this.value=this.value.replace(/\s+/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="colseEditProcessBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="saveProcessData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 部门选择弹框组件 -->
    <treeDepartment @SelectedData="getDepartmentData" v-if="show_departmentBox"></treeDepartment>

    <!-- 负责人选择弹框组件 -->
    <personnelList @SelectedData="getPersonnelData" :defaultSelected="defaultSelected" v-if="show_personnelbox">
    </personnelList>

    <!-- 供应商选择组件-->
    <supplierList @SelectedData="getSupplierData" :isMultiple="1" v-if="show_supplierBox"></supplierList>

     <!-- 附件预览组件 -->
     <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //请求相关
  import api from '@/network/system/dictionary'; //数据字典相关请求

  // 导入组件
  import treeDepartment from "@/components/commComponent/tree/tree_department.vue"; //部门组件
  import personnelList from "@/components/commComponent/list/list_personnel.vue"; //负责人组件
  import supplierList from "@/components/commComponent/list/list_supplier.vue"; //供应商组件
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  export default {
    props: {
      // 操作方式(0:新增  1:修改)
      operatedState: {
        type: Number,
        default: 0
      },
      // 操作界面(0:工序主界面  1:生产计划单界面)
      operatedType: {
        type: Number,
        default: 0
      },
      // 回显的工序数据源
      mesProcessData: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data() {
      return {
        //组件数据
        processData: { //工序数据
          id: '',
          old_name: '',
          encoding: '', //编码
          name: '', //工序名称
          personnel: '', //负责人
          personnelId: '', //负责人Id
          work_num: 0,
          department: '', //部门
          departmentId: '', //部门Id
          cost: '', //计费方式
          costId: '', //计费方式Id
          supplier: '', //供应商
          supplierId: '', //供应商Id
          type: '', //类型
          typeId: '', //类型Id
          typeData: {}, //生产类型数据
          classfiy: '', //所属分类
          classfiyId: '', //分类id
          time: '', //用时
          total_labour_cost: '', //总工价
          manufacturing_allocation_cost:'',//制造分摊成本
          manufacturingAllocationType:[],//类型
          manage_cost_allocation:'',//管理分摊成本
          other_apportioned_costs:'',//其它分摊成本
          tax_rage: 0, //税率
          price: '', //工价
          min_work_amount: '', //最低开工费用
          min_work_number: '', //最低开工费配额
          remark: '', //备注
          is_enable: true,
        },
        supplierList: [], //供应商数组(工序执行单时使用)

        //文件相关
        APi: baseUrl + '/mes/addMesMainProcessImg', //图纸
        ImgUrlStr: '', //url字符串
        imgUrl: [], //file-list
        ImgUrlArr: [], //图片地址数组
        //请求头
        headers: {
          token: '',
        },

        //子组件数据
        defaultSelected: [], //默认选中的员工

        //控制开关
        show_processform: false, //控制弹框是否显示
        show_departmentBox: false, //是否显示部门弹框
        show_personnelbox: false, //是否显示负责人弹框
        show_supplierBox: false, //是否显示供应商组件

        //控制编辑
        disableBase:true,//编辑基本信息(工序主界面可编辑,生产计划不可编辑)
        disableCost:true,//编辑费用信息

        //辅助数据
        form_rules: {}, //表单验证规则

        //预览文件
        previewFilePath: '', //附件预览路径
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        CostType: state => state.system.CostType, //计费方式
        ManufactureType: state => state.system.ManufactureType, //生产类型
        ProductionBomType: state => state.system.ProductionBomType, //BOM分类
        userPowerInfo: state => state.user.userPowerInfo, //权限
        companyparamlist: state => state.companyparamlist, //企业级权限参数
      })
    },
    mounted() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
      //初始化表单验证规则
      this.initRules();
      //获取请求头
      this.headers.token = getCookie('token');
    },
    methods: {
      ...mapMutations([
        'SHOW_DEPARTMENTBOX', //部门选择组件
        'SHOW_PERSONNELBOX', //负责人选择组件
        'SHOW_SUPPLIERBOX', //供应商选择组件
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),

      ...mapActions([
        'getAllMesCostType', //计费方式
        'getAllManufactureType', //生产类型
        'getAllBomType', //BOM分类
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化组件所需数据 */
      async initVuextData() {
        //计费方式
        if (this.CostType.length == 0) {
          await this.getAllMesCostType();
        }
        //生产类型
        if (this.ManufactureType.length == 0) {
          await this.getAllManufactureType();
        }
        //bom分类
        if (this.ProductionBomType.length == 0) {
          await this.getAllBomType();
        }
        //处理界面控制权限
        this.disposeUserPower();
        //获取界面数据
        this.judgeProcessOperateData();
      },

      /*计算预计总工价*/
      estimatedTotalPrice(){
          if(this.processData.cost==='计件'){
            this.processData.total_labour_cost=this.processData.price*this.processData.work_num
          }else{
            this.processData.total_labour_cost=this.processData.time*this.processData.price*this.processData.work_num
          }
      },

      /* 处理企业控制权限 */
      disposeUserPower(){
        //判断操作界面
        if(this.operatedType == 0){//工序主界面
          this.disableBase = true;
          this.disableCost = true;
        }else if(this.operatedType == 1){//生产界面
          //基本信息不可编辑
          this.disableBase = false;
          //费用信息判断权限()
          if(this.companyparamlist.param361){
            //获取设置的用户数据
            let userArr = this.companyparamlist.param361.split(",");
            //获取用户id数组
            let userIdArr = [];
            userArr.forEach((item,index)=>{
              let userItemArr = item.split("@@@");
              userIdArr.push(parseInt(userItemArr[0]));
            })
            //判断当前用户是否在里面
            if(userIdArr.includes(this.UserInfo.user_id)){//费用可编辑
              this.disableCost = true;
            }else{
              this.disableCost = false;
            }
          }else{
            this.disableCost = false;
          }
        }
      },

      /* 判断工序操作类型 */
      judgeProcessOperateData() {
        if (this.operatedState == 0) { //新增
          this.addProcessMehod();
        } else if (this.operatedState == 1) { //修改
          this.updatePrcessMethod();
        }
      },

      previewDrawing(file){
        if(!!file.response){
          //获取预览路径
          this.previewFilePath = file.name + "@@@" + file.response.data;
        }
        else if(!!file.url){
         let url= file.url.split("//upload")
         let urlValue="//upload"+url[1]
         this.previewFilePath = file.name + "@@@" + urlValue;
        }
        console.log(this.previewFilePath)
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      /* 添加工序方法 */
      addProcessMehod() {
        //清空数据
        this.processData = { //工序数据
          id: '',
          old_name: '',
          encoding: '', //编码
          name: '', //工序名称
          personnel: '', //负责人
          personnelId: '', //负责人Id
          work_num: 0,
          department: '', //部门
          departmentId: '', //部门Id
          cost: '', //计费方式
          costId: '', //计费方式Id
          supplier: '', //供应商
          supplierId: '', //供应商Id
          type: '', //类型
          typeId: '', //类型Id
          typeData: {}, //生产类型数据
          classfiy: '', //所属分类
          classfiyId: '', //分类id
          time: 0, //用时
          total_labour_cost: 0, //总工价
          manufacturing_allocation_cost:'',//制造分摊成本
          manage_cost_allocation:'',//管理分摊成本
          other_apportioned_costs:'',//其它分摊成本
          tax_rage: 0, //税率
          price: 0, //工价
          min_work_amount: 0, //最低开工费用
          min_work_number: 0, //最低开工费配额
          remark: '', //备注
          is_enable: true,
        }

        //默认BOM分类
        if (this.ProductionBomType.length != 0) {
          this.processData.classfiy = this.ProductionBomType[0].name;
          this.processData.classfiyId = this.ProductionBomType[0].id;
        }

        //默认计费方式
        if (this.CostType.length != 0) {
          //赋值新增弹框默认值
          this.processData.cost = this.CostType[0].name;
          this.processData.costId = this.CostType[0].id;
        }

        //默认生产类型
        if (this.ManufactureType.length != 0) {
          //赋值新增弹框默认值
          this.processData.type = this.ManufactureType[0].name;
          this.processData.typeId = this.ManufactureType[0].id;
          this.processData.typeData = this.ManufactureType[0];
        }
        //清空图片
        this.ImgUrlArr = [];
        this.imgUrl = [];

        //显示弹框
        this.show_processform = true;
      },

      /* 修改工序数据 */
      updatePrcessMethod() {
        //处理驼峰
        for (let i in this.mesProcessData) {
          let line = this.commonJsExtend.toLine(i);
          this.mesProcessData[line] = this.mesProcessData[i];
        }
        console.log(this.mesProcessData);

        //获取回显
        this.processData = { //工序数据
          id: this.mesProcessData.id, //工序id
          old_name: this.mesProcessData.name, //旧名称
          encoding: this.mesProcessData.encoding, //编码
          name: this.mesProcessData.name, //工序名称
          personnel: this.mesProcessData.user_name, //负责人
          personnelId: this.mesProcessData.user_id, //负责人Id
          work_num: this.mesProcessData.work_num, //派工人数
          department: this.mesProcessData.dept_name, //部门
          departmentId: this.mesProcessData.dept_id, //部门Id
          cost: this.mesProcessData.cost_type_name, //计费方式
          costId: this.mesProcessData.cost_type_id, //计费方式Id
          supplier: this.mesProcessData.supplier_name, //供应商
          supplierId: this.mesProcessData.supplier_id, //供应商Id
          type: this.mesProcessData.manufactureTypeName, //类型
          typeId: this.mesProcessData.manufacture_type_id, //类型Id
          typeData: this.commonJsExtend.getDictMatchingData(this.mesProcessData.manufacture_type_id, this
            .ManufactureType), //生产类型数据
          classfiy: this.mesProcessData.processTypeName, //所属分类
          classfiyId: this.mesProcessData.process_type_id, //分类id
          time: this.mesProcessData.hours, //用时
          total_labour_cost: this.mesProcessData.total_labour_cost, //总工价
          manufacturing_allocation_cost:!!this.mesProcessData.manufacturing_allocation_cost?Number(this.mesProcessData.manufacturing_allocation_cost.split("@@@")[0]):'',//制造分摊成本
          manufacturingType:!!this.mesProcessData.manufacturing_allocation_cost?this.mesProcessData.manufacturing_allocation_cost.split("@@@")[1]:'',
          manage_cost_allocation:!!this.mesProcessData.manage_cost_allocation?Number(this.mesProcessData.manage_cost_allocation.split("@@@")[0]):'',//管理分摊成本
          manageType:!!this.mesProcessData.manage_cost_allocation?this.mesProcessData.manage_cost_allocation.split("@@@")[1]:'',
          other_apportioned_costs:!!this.mesProcessData.other_apportioned_costs?Number(this.mesProcessData.other_apportioned_costs.split("@@@")[0]):'',//其它分摊成本
          otherType:!!this.mesProcessData.other_apportioned_costs?this.mesProcessData.other_apportioned_costs.split("@@@")[1]:'',
          tax_rage: this.mesProcessData.tax_rage, //税率
          price: this.mesProcessData.labour_cost, //工价
          min_work_amount: this.mesProcessData.min_work_amount, //最低开工费用
          min_work_number: this.mesProcessData.min_work_number, //最低开工费配额
          remark: this.mesProcessData.remark, //备注
          is_enable: this.mesProcessData.is_enable == 1 ? true : false, //是否启用
        }

        //相关附件
        if (this.mesProcessData.img_url != null) {
          let imgarr = this.mesProcessData.img_url.split(",")
          if (imgarr[0] != '') {
            imgarr.forEach((item, index) => {
              this.ImgUrlArr.push(item)
              let foo = item.split("@@@")
              let imgobj = {}
              let imgurl = baseUrl + foo[0]
              imgobj.name = foo[1]
              imgobj.url = imgurl
              this.imgUrl.push(imgobj)
            })
          }
        }

        //判断工序单获取供应商数据
        if (this.operatedType == 1) {
          //清空
          this.supplierList = [];
          //获取数据
          console.log(this.mesProcessData);
          if (this.mesProcessData.supplier_ids && this.mesProcessData.supplier_names) {
            //分割字符串获取数组
            let supplierIdsArr = this.mesProcessData.supplier_ids.split(",");
            let supplierNameArr = this.mesProcessData.supplier_names.split(",");
            //循环获取供应商数组
            supplierIdsArr.forEach((item, index) => {
              let supplierItem = {
                supplierId: item,
                supplierName: supplierNameArr[index]
              }
              this.supplierList.push(supplierItem)
            })
          }
        }

        //显示弹框
        this.show_processform = true;
      },

      /* 保存工序数据 */
      saveProcessData() {
        this.$refs['add_ruleform'].validate(valid => {
          if (valid) {
            //获取图片数据
            this.processData.ImgUrlArr = this.ImgUrlArr;
            //将数据传回父组件
            this.$emit("getMesProcessData", 0, this.processData);
          }
        })
      },

      /* 关闭工序信息修改弹框 */
      colseEditProcessBox() {
        //关闭弹框
        this.show_processform = false;
        //将数据传回父组件
        this.$emit("getMesProcessData", 1, this.processData);
      },

      /* 弹框组件 */
      judgeRelatedData(type) {
        //判断显示弹框类型
        if (type == 1) { //部门
          this.show_departmentBox = true;
          this.SHOW_DEPARTMENTBOX(true); //部门
        } else if (type == 2) { //负责人
          this.show_personnelbox = true;
          this.SHOW_PERSONNELBOX(true); //负责人
        } else if (type == 3) { //供应商
          this.show_supplierBox = true;
          this.SHOW_SUPPLIERBOX(true); //供应商
        }
      },

      /* 点击获取计费方式Id */
      getCostData(data) {
        //赋值新增弹框默认值
        this.processData.cost = data.name;
        this.processData.costId = data.id;
      },

      /* 点击获取生产类型Id */
      getTypeData(data) {
        this.processData.type = data.name;
        this.processData.typeId = data.id;
        this.processData.typeData = data;
      },

      /* 点击BOM分类Id */
      getBomTypeData(data) {
        this.processData.classfiy = data.name;
        this.processData.classfiyId = data.id;
      },

      /* 获取点击部门数据 */
      getDepartmentData(data) {
        this.show_departmentBox = false;
        let result = this.commonJsExtend.getDeptMsg(data);
        this.processData.department = result.nameStr; //部门
        this.processData.departmentId = result.idStr; //部门id
        this.$forceUpdate()
      },

      /* 点击获取负责人信息 */
      getPersonnelData(data) {
        //关闭加载的负责人组件信息
        this.show_personnelbox = false;
        this.defaultSelected = data;
        //获取名称,id字符串
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //判断打开方式
        this.processData.work_num = data.length; //获取执行人数
        this.processData.personnel = result.nameStr; //名称str
        this.processData.personnelId = result.idStr; //名称idstr
        this.$forceUpdate()
      },

      /* 获取选择供应商数据 */
      getSupplierData(data) {
        let supplierNameArr = [];
        let supplierIdArr = [];
        if (data.length > 0) {
          data.forEach((item, index) => {
            supplierNameArr.push(item.fullName);
            supplierIdArr.push(item.id);
          })
        }
        this.processData.supplier = supplierNameArr.join(",");
        this.processData.supplierId = supplierIdArr.join(",");
      },

      /* 获取供应商信息(工序单使用) */
      getSupplierListObj(data){
        this.processData.supplier = data.supplierName;
        this.processData.supplierId = data.supplierId;
      },

      /* 图片上传成功的回调 */
      getImgUrl(res, file, fileList) {
        this.ImgUrlArr.push(res.data)
      },

      /* 删除图片 */
      removeFile(file, fileList) {
        //删除传递到后端数组中的数据
        this.ImgUrlArr.forEach((item, index) => {
          if (file.hasOwnProperty("url")) {
            let url = item.substr(0, item.indexOf("@"))
            let imgurl = baseUrl + url
            if (imgurl == file.url) {
              this.ImgUrlArr.splice(index, 1)
            }
          } else {
            if (item == file.response.data) {
              this.ImgUrlArr.splice(index, 1)
            }
          }
        })
      },

      /* 初始化表单信息 */
      initRules() {
        this.form_rules = {
          name: [{ //规则1
            required: true, //是否必填
            message: '请输入名称', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          // encoding: [{ //规则1
          //   required: true, //是否必填
          //   message: '请输入编码', //该规则错误信息提示
          //   trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          // }, ],
          classfiy: [{ //规则1
            required: true, //是否必填
            message: '请选择分类', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          personnel: [{ //规则1
            required: true, //是否必填
            message: '请选择负责人', //该规则错误信息提示
            trigger: 'change' //哪种事件触发(change为改变事件)
          }, ],
        }
      },
    },
    components: {
      treeDepartment,
      personnelList,
      supplierList,
      filePreview
    }
  }
</script>

<style lang="less" scoped="scoped">
  .billing_dialog_form {
    height: 60vh;
    overflow: auto;
    // border: 1px solid black;
  }
  .manufacturingTypeClass{
    padding-top: 10px;
    padding-left: 20px;
  }
  .costClass{
    display: block;
    color: #606266;
    font-weight: bold;
    margin-bottom: 10px;
  }

  // .billing_dialog_form:hover {
  //   overflow: auto;
  // }
</style>
