<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
  技术支持：Tech@21gmail.com
  单元名称：部门选择弹框组件
  开始时间：2021-01-15
  开发人员：刘巍骏,万攀
  最后修改：2021-01-15
  备注说明：如需修改请联系开发人员
  -->
<template>
  <div class="">
    <el-dialog title="负责部门" :visible.sync="show_departmentBox" :before-close="colse_departmentBox" width="400px"
      append-to-body>
      <div class="department_Box">
        <el-tree :data="departmentData" :props="defaultProps" default-expand-all show-checkbox :check-strictly="true"
          @check-change="SelectDepartment">
        </el-tree>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_department">确认</el-button>
        <el-button size="mini" @click="cancel_department">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  //导入方法
  import api from "@/network/hr/organization";

  export default {
    name: 'tree_department',
    data() {
      return {
        // Element ui相关
        defaultProps: { //匹配接口字段信息
          children: 'children',
          label: 'dept_name'
        },
        SelectData: [], //选中的数据
      };
    },
    mounted() {
      // 判断vuex是否存在部门数据
      this.judjeDepartmentData();
    },
    computed: {
      ...mapState({
        show_departmentBox: state => state.commComponent.show_departmentBox, //控制显示部门选择弹框组件
        departmentData: state => state.hr.departmentData, //部门数据
      }),
    },
    methods: {
      ...mapMutations([
        'SHOW_DEPARTMENTBOX', //控制单据选择弹框是否显示
        'DEPARTMENTDATA', //将部门信息存入
      ]),

      /* 判断vuex是否存在数据*/
      judjeDepartmentData() {
        // 无数据则发送请求
        if (!this.departmentData.length > 0) {
          // 获取部门信息
          this.getDepartmentData();
        }
      },
      /* 获取选中的部门 */
      SelectDepartment(row, is_check, is_child) {
        if (is_check) {
          this.SelectData.push(row)
        } else {
          this.SelectData.forEach((item, index) => {
            if (item.dept_id == row.dept_id) {
              this.SelectData.splice(index, 1)
            }
          })
        }
      },
      /* 获取部门数据*/
      getDepartmentData() {
        // 发送请求
        api.getDepartmentTree().then(res => {
          if (res.length > 0) {
            this.DEPARTMENTDATA(res);
          }
        })
      },
      /* 关闭弹框 */
      colse_departmentBox() {
        this.SHOW_DEPARTMENTBOX(false);
      },

      // 提交选择部门
      commit_department() {
        this.$emit('SelectedData', this.SelectData)
        this.SHOW_DEPARTMENTBOX(false);
      },
      // 取消选择部门
      cancel_department() {
        this.SHOW_DEPARTMENTBOX(false);
      },
    }
  };
</script>

<style lang="less" scoped="scoped">
  .department_Box {
    width: 100%;
    height: 300px;
    overflow: auto;
    // border: 1px solid black;
  }
</style>
